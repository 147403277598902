body {
    color: $main-dark;
}
#app{
    @media screen and (max-width: 767px){
        padding-bottom: 87px;
    }
}
[v-cloak] {
    display: none;
}
.navbar {
    z-index: 2;
    .account-link{
        text-decoration: none;
        color: $gray-light;
        display: flex;
        align-items: center;
        font-size: 15px;
    }
}
.footer{
    text-align: center;
    color: $gray-light;
    font-size: 15px;
    margin-top: 120px;
    &:before{
        content: "";
        display: block;
        width: 90%;
        height: 7px;
        margin: 0 auto 24px;
        background-image: url(../../public/images/decorative_line-footer.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
.btn {
    font-weight: 600;
    &:focus {
        box-shadow: none;
    }
}
.btn-warning {
    color: $main-dark;
}
.btn-secondary{
    &:disabled, &.disabled{
        color: $gray-light;
    }
}
.btn-checkout {
    height: 55px;
    line-height: 40px;
}
.btn-primary {
    background: rgb(22, 190, 238);
    background: linear-gradient(
        -90deg,
        rgba(22, 190, 238, 1) 0%,
        rgba(84, 210, 141, 1) 100%
    );
    border: none;
    color: #fff;
}
.btn-primary:hover {
    background: rgb(22, 190, 238);
    background: linear-gradient(
        90deg,
        rgba(22, 190, 238, 1) 0%,
        rgba(105, 216, 247, 1) 100%
    );
    border: none;
    color: #fff;
    position: relative;
}
.success-page {
    text-align: center;
    display: flex;
    height: calc(100vh - 73px);
    .inner-content-wrapper {
        & > img {
            margin-bottom: 16px;
        }
    }
}
ul.errors {
    margin: 0;
}
.checkout-steps {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 28px 0 76px;
    padding: 0;
    & > li {
        text-transform: uppercase;
        font-weight: bold;
        color: #bbc4ce;
        font-size: 14px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 767px){
            font-size: 13px;
        }
        &.active {
            color: $main-dark;
        }
        &:not(:last-child):after {
            content: "";
            margin-left: 20px;
            margin-right: 20px;
            display: inline-block;
            width: 18px;
            height: 11px;
            background-image: url(../../public/images/arrow_icon.svg);
            transform: rotate(180deg);
            opacity: 0.5;
            @media screen and (max-width: 767px){
                margin-right: 10px;
                margin-left: 10px;
            }
        }
    }
}
.navigate-back {
    text-decoration: none;
    color: $gray-light;
    &:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        width: 18px;
        height: 11px;
        background-image: url(../../public/images/arrow_icon.svg);
        transition: transform 0.6s ease-in-out;
    }
    &:hover {
        color: $gray-light;
        &:before {
            transform: translateX(-4px);
        }
    }
}
.form-back{
    color: $main-dark;
    position: absolute;
    top: 38px;
    left: 52px;
    display: inline-block;
    font-size: 24px;
    cursor: pointer;
    width: auto;
    margin-top: 0;
}
.loder-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.alert {
    border-radius: 0;
    &.alert-warning {
        color: #c08f37;
        border-color: #fad766;
        .alert-icon {
            background-image: url(../../public/images/warning_icon.svg);
            flex-basis: 21px;
            margin-right: 12px;
            margin-top: 2px;
        }
        .alert-text {
            flex-basis: calc(100% - 21px);
        }
    }
    .alert-icon {
        width: 21px;
        height: 21px;
        background-repeat: no-repeat;
        background-size: contain;
    }
}
.content-box {
    background-color: #fff;
    padding: 31px 35px;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 3%);
    @media screen and (max-width: 767px){
        padding: 19px;
    }
}
.form-switch.privacy-form-switch .form-check-input {
    background-color: #eef0f3;
    border-color: #eef0f3;
    margin-left: initial;
    width: 56px;
    height: 24px;
    cursor: pointer;
    background-image: url(../../public/images/checkbox_circle-inactive.svg);
    &:focus {
        box-shadow: none;
    }
    &:checked {
        background-color: #d9f5fd;
        border-color: #d9f5fd;
        background-image: url(../../public/images/checkbox_circle-active.svg);
    }
}
.preset-radio{
    visibility: hidden;
    position: absolute;
    & + label{
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid #E0E0E0;
        height: 60px;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &:checked + label{
        border-color: $green;
        background-color: #F0FBF5;
    }
}
.btn-add-to-cart {
    background-color: #fad766;
    border-color: #fad766;
    color: $main-dark;
    min-width: 124px;
    &.in-cart {
        color: $green;
        background-color: #ebf7f1;
        border-color: #ebf7f1;
        padding-left: 40px;
        position: relative;
        &:before {
            content: "";
            display: block;
            width: 21px;
            background-image: url(../../public/images/check_icon.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 20px;
            position: absolute;
            left: 20px;
        }
    }
    &.unavailable {
        color: #cdcfd3;
        background-color: #edf0f2;
        border-color: #edf0f2;
    }
}
.navbar {
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 4%);
}
.auth-form {
    h2 {
        text-transform: uppercase;
        color: $gray-light;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
    }
    .content-box {
        padding: 48px 52px;
        @media screen and (max-width: 767px){
            padding: 36px 19px;
        }
    }
    .form-group {
        margin-bottom: 19px;
    }
    .switch-form {
        font-weight: bold;
        text-decoration: none;
    }
    .password-reset-link {
        font-weight: 400;
        color: $gray-light;
    }
}
.form-control,
.form-select {
    border-color: #dbdbdb;
    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #a4abbc;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #a4abbc;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #a4abbc;
    }
}
.form-control {
    padding: 0.9rem 1.125rem;
}
.form-select {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    padding-right: 1.65rem;
}
.primary-button-group {
    width: 618px;
    max-width: 100%;
    label.btn {
        font-family: $font-family-caveat;
        font-size: 25px;
        color: #8d97ad;
        background-color: #f1f3f4;
        border: 1px solid #f1f3f4;
        font-weight: 400;
    }
    .btn-check:checked + label.btn {
        color: $green;
        border-color: $green;
        background-color: #ebf7f1;
    }
}
.inner-content-wrapper {
    width: 825px;
    max-width: 100%;
    margin: auto;
    min-height: calc(100vh - 296px);
    .page-title {
        font-family: $font-family-caveat;
        font-size: 39px;
        font-weight: 400;
        text-align: center;
    }
    .page-subtitle {
        text-align: center;
    }
    .hosting-item-component {
        background-color: #fff;
        box-shadow: 0 3px 30px 0 rgb(0 0 0 / 3%);
        margin-bottom: 34px;
        margin-top: 24px;
        &.highlighted{
            box-shadow: 0 0 20px 0px rgb(243 202 71 / 25%);
        }
        #domain-lookup-result,
        .domain-suggestions,
        .domain-block,
        .hosting-block {
            padding: 31px 35px;
            @media screen and (max-width: 767px){
                padding: 19px;
            }
        }
        .domain-block {
            border-bottom: 1px solid #EFEFEF;
            .domain-data {
                display: flex;
                justify-content: space-between;
                padding-left: 35px;
                padding-right: 35px;
                align-items: center;
                @media screen and (max-width: 767px){
                    flex-direction: column;
                }
                .domain-name {
                    font-size: 19px;
                    display: flex;
                    @media screen and (max-width: 767px){
                        font-size: 16px;
                    }
                    &:before {
                        content: "";
                        display: block;
                        width: 31px;
                        height: 29px;
                        background-image: url(../../public/images/domain_icon.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        margin-right: 16px;
                        @media screen and (max-width: 767px){
                            margin-right: 4px;
                        }
                    }
                }
                .domain-price {
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    @media screen and (max-width: 767px){
                        width: 100%;
                    }
                    select.duration{
                        border: none;
                        background-color: transparent;
                        font-weight: 600;
                        &:focus{
                            box-shadow: none;
                        }
                    }
                }
            }
            .privacy-form-switch {
                display: flex;
                justify-content: space-between;
                padding-left: 0;
                padding-left: 35px;
                padding-right: 35px;
            }
            .separator {
                content: "";
                width: 100%;
                height: 16px;
                display: block;
                background-image: url(../../public/images/decorative_line-domain.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                margin-top: 19px;
                margin-bottom: 23px;
            }
            @media screen and (max-width: 767px){
                .privacy-form-switch, .domain-data{
                    padding-left: 0;
                    padding-right: 0;
                }
                .form-check-label{
                    max-width: 80%;
                }
            }
        }
        .hosting-block {
            .hosting-block-title {
                padding-left: 35px;
                padding-right: 35px;
                font-family: $font-family-sans-serif;
                font-size: 23px;
                font-weight: 400;
                display: flex;
                margin-bottom: 25px;
                @media screen and (max-width: 767px){
                    font-size: 19px;
                }
                &:before {
                    content: "";
                    display: block;
                    width: 29px;
                    height: 36px;
                    background-image: url(../../public/images/hosting_icon.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 16px;
                }
            }
            .hosting-features {
                .feature {
                    background-color: #f3fcff;
                    padding: 6px 35px;
                    color: $gray-light;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    &:before {
                        content: "";
                        display: block;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        margin-right: 16px;
                        width: 38px;
                    }
                    &.feature-ssl:before {
                        height: 33px;
                        background-image: url(../../public/images/feature_icon-ssl.svg);
                    }
                    &.feature-support:before {
                        height: 36px;
                        background-image: url(../../public/images/feature_icon-phone.svg);
                    }
                    &.feature-backup:before {
                        height: 28px;
                        background-image: url(../../public/images/feature_icon-backup.svg);
                    }
                }
            }
        }
    }
    .lookup-result-item {
        margin-bottom: 32px;
        @media screen and (max-width: 767px){
            margin-bottom: 16px;
        }
        & > .row{
            flex: 1;
        }
        .domain-wrapper {
            display: flex;
            &:before {
                content: "";
                display: block;
                width: 28px;
                height: 28px;
                background-image: url(../../public/images/checkbox-available.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                margin-right: 13px;
            }
            .domain-name{
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &.unavailable {
            .domain-wrapper {
                color: #b2b5bb;
                &:before {
                    background-image: url(../../public/images/checkbox-unavailable.svg);
                }
            }
        }
        .actions {
            display: flex;
            align-items: center;
            justify-content: center;
            & > select.duration {
                border: none;
                background-color: transparent;
                font-weight: 600;
                font-size: 15px;
                &:focus {
                    box-shadow: none;
                }
                @media screen and (max-width: 767px){
                    margin-right: 0;
                    padding-left: 0;
                    padding-right: 24px;
                    font-size: 14px;
                }
            }
            .domain-unavailable {
                font-weight: bold;
                color: $gray-light;
            }
        }
    }
    #domain-lookup-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        .search-input-group {
            border-bottom: 1px solid #bbc4ce;
            margin-bottom: 60px;
            @media screen and (max-width: 767px){
                margin-bottom: 16px;
            }
            .search-input {
                background-color: transparent;
                border: none;
                font-size: 23px;
                color: $main-dark;
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}
.cart-component {
    background-color: $main-dark;
    position: fixed;
    height: calc(100% - 72px);
    z-index: 2;
    right: 0;
    // top: 72px;
    // overflow-y: auto;
    .mobile-cart-toggler {
        display: none;
        position: absolute;
        border: none;
        width: 80px;
        height: 8px;
        left: 50%;
        transform: translateX(-50%);
        top: 0px;
        border-radius: 4px;
        background-color: #ffffff61;
        @media screen and (max-width: 767px){
            display: block;
        }
    }
    .cart-wrapper {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        h4 {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 16px;
        }
        .cart-items {
            list-style-type: none;
            padding: 0;
            .cart-item {
                background-color: rgba(255, 255, 255, 0.07);
                padding: 17px 23px;
                border-radius: 4px;
                .cart-item-domain,
                .cart-item-hosting,
                .cart-item-privacy {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    @media screen and (max-width: 767px){
                        flex-direction: column;
                    }
                    & > * {
                        flex: 1;
                        @media screen and (max-width: 767px){
                            width: 100%;
                            text-align: left;
                            position: relative;
                        }
                        &.price{
                            text-align: right;
                            @media screen and (max-width: 767px){
                                text-align: left;
                            }
                        }
                    }
                    .btn-remove-item {
                        z-index: 1;
                        background-image: url(../../public/images/cart_delete_item_icon.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        width: 10px;
                        height: 13px;
                        @media screen and (max-width: 767px){
                            position: absolute;
                            right: 0;
                            top: 4px;
                        }
                    }
                }
                .cart-item-privacy {
                    margin-top: 21px;
                }
            }
        }
        .empty-cart-message {
            padding-top: 272px;
            width: 336px;
            max-width: 100%;
            text-align: center;
            background-image: url(../../public/images/cart_illustration.svg);
            background-repeat: no-repeat;
            background-position: top center;
            font-size: 16px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: rgb(255 255 255 / 73%);
        }
        &.cart-not-empty:before {
            content: "";
            display: block;
            width: 190px;
            height: 249px;
            background-image: url(../../public/images/cart_illustration.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto;
            position: absolute;
            left: 50%;
            bottom: 210px;
            transform: translateX(-50%);
            opacity: 0.13;
            pointer-events: none;
        }
    }
    .separator {
        width: 100%;
        height: 16px;
        display: block;
        background-image: url(../../public/images/decorative_line-cart.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-top: 38px;
        margin-bottom: 16px;
    }
    .total-amount {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .amount {
            font-size: 23px;
            font-weight: 700;
        }
    }
    @media screen and (max-width: 767px) {
        bottom: 0;
        height: auto;
        &:not(.is-open){
            .cart-wrapper{
                h4, .cart-items, .separator{
                    display: none;
                }
            }
        }
        &.is-open{
            height: 100%;
        }
    }
}

.hosting-item-component {
    .epp-box {
        width: 579px;
        max-width: 100%;
        margin: auto;
        text-align: center;
        .epp-input-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 508px;
            max-width: 100%;
            margin: 18px auto 24px;
            border: 1px solid #cacaca;
            padding: 8px;
            .form-control {
                border: none;
                &:focus {
                    box-shadow: none;
                }
            }
        }
        .epp-tip {
            color: $gray-light;
        }
    }
    .hosting-plans-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 34px;
        @media screen and (max-width: 767px){
            flex-direction: column;
        }
        .hosting-item-plan-component {
            width: 336px;
            max-width: 100%;
            border: 1px solid #cbcfd8;
            text-align: center;
            padding: 24px 36px 36px;
            margin-right: 10px;
            margin-left: 10px;
            @media screen and (max-width: 767px){
                margin-right: 0;
                margin-left: 0;
                margin-bottom: 30px;
                padding: 16px;
            }
            &.active {
                border-color: $green;
            }
            .plan-title {
                font-size: 28px;
                font-family: $font-family-caveat;
                color: $green;
                font-weight: 400;
            }
            .plan-price {
                font-size: 28px;
                font-weight: bold;
                small {
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            .plan-slogan {
                font-size: 15px;
            }
            .plan-specs-wrapper {
                @media screen and (min-width: 768px){
                    min-height: 370px;
                }
            }
            .plan-specifications {
                list-style-type: none;
                color: $gray-light;
                padding: 0;
                li {
                    margin-top: 9px;
                    margin-bottom: 9px;
                }
                &:before,
                &:after {
                    content: "";
                    width: 150px;
                    max-width: 100%;
                    height: 7px;
                    display: block;
                    background-image: url(../../public/images/decorative_line-plan.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    margin: 18px auto 12px;
                }
            }
            .btn-select-hosting {
                display: flex;
                height: 55px;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #d2d5dd;
                margin-bottom: 10px;
                padding: 16px 18px;
                border-radius: 5px;
                cursor: pointer;
                .amount {
                    font-weight: bold;
                }
                &.active {
                    border-color: $green;
                    background-color: #f0fbf5;
                    .amount {
                        color: $green;
                    }
                }
            }
        }
    }
}
.reassurance-block {
    display: flex;
    align-items: center;
    background-color: $main-dark;
    box-shadow: 20px 0 20px rgb(0 0 0 / 8%);
    position: fixed;
    height: 100%;
    z-index: 1;
    top: 0;
    bottom: 0;
    @media (max-width: 1310px), (max-height: 776px) {
        padding-top: 24px;
    }
    .reassurance-block-content-wrapper {
        padding: 49px;
        .block-title {
            font-family: $font-family-caveat;
            font-size: 39px;
            margin-bottom: 18px;
            line-height: normal;
        }
        .block-subtitle {
            margin-bottom: 36px;
        }
        .benefits-list {
            .benefit {
                display: flex;
                margin-bottom: 24px;
                .icon-wrapper {
                    color: $green;
                    padding-right: 16px;
                }
                .benefit-title {
                    font-weight: bold;
                }
                .benefit-text {
                    font-weight: 300;
                }
            }
        }
        @media (max-width: 1310px), (max-height: 776px) {
            padding: unset;
            font-size: 15px;
        }
    }
}
.footer-cart-component {
    position: fixed;
    bottom: 0;
    background-color: $main-dark;
    color: white;
    width: 100%;
    right: 0;
    padding: 23px 47px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 19px;
    .amount {
        font-size: 28px;
        font-weight: bold;
        margin-right: 61px;
        margin-left: 26px;
        white-space: nowrap;
        @media screen and (max-width: 767px){
            margin-right: 24px;
            margin-left: 24px;
            font-size: 23px;
        }
    }
    .btn-checkout {
        width: 316px;
        max-width: 100%;
    }
    @media screen and (max-width: 767px){
        font-size: 16px;
        padding: 16px 12px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
}
.has-footer-cart {
    margin-bottom: 125px;
}
.payment-methods-wrapper {
    .methods-group-title {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        color: $green;
        text-align: center;
        margin-bottom: 34px;
        margin-top: 32px;
        &.with-separator {
            display: block;
            background-image: url(../../public/images/decorative_line-domain.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            text-shadow: 3px 0px 0px #f8fbfb, -3px 0px 0px #f8fbfb;
            margin-bottom: 26px;
        }
    }
    .payment-method-option{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        cursor: pointer;
        &.active{
            border: 1px solid #56d38b;
            box-shadow: 0 0px 10px 0 rgb(86 211 139 / 46%);
        }
    }
}
.cart-page {
    .inner-content-wrapper {
        width: 655px;
        max-width: 100%;
        .cart-item {
            .domain-block,
            .hosting-block {
                display: flex;
                .block-icon {
                    margin-right: 16px;
                }
                .block-data {
                    width: 100%;
                    & > *:not(.domain-name) {
                        display: flex;
                        justify-content: space-between;
                        &:not(:first-child) {
                            margin-top: 12px;
                        }
                        .title, .price{
                            flex: 1;
                        }
                        .price{
                            text-align: right;
                        }
                    }
                    .actions {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                        display: flex;
                        & > li {
                            display: flex;
                            align-items: center;
                            &:not(:last-child):after {
                                content: "";
                                width: 1px;
                                height: 13px;
                                background-color: #bbc4ce;
                                display: block;
                                margin-left: 8px;
                                margin-right: 8px;
                            }
                        }
                    }
                    .duration {
                        color: $gray-light;
                        border: none;
                        border-radius: 4px;
                        padding: 3px 0;
                    }
                    .price {
                        font-weight: bold;
                    }
                }
            }
            .hosting-block-wrapper::before {
                content: "";
                width: 100%;
                height: 16px;
                display: block;
                background-image: url(../../public/images/decorative_line-domain.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                margin-top: 19px;
                margin-bottom: 23px;
            }
        }
    }
}
