// Body
$body-bg: #f9fbfb;

// Typography
$font-family-sans-serif: "Open Sans", sans-serif;
$font-family-caveat: "Caveat", cursive;
$font-size-base: 1rem;
$line-height-base: 1.6;

// Colors
$blue: #69d8f7;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #ff4d4d;
$orange: #f6993f;
$yellow: #fad766;
$green: #56d38b;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$gray-light: #7e889f;
$secondary: #C8CDD6;

$main-dark: #3e4555;
