// Fonts
@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Open+Sans:wght@300;400;600;700&display=swap");

// Icons
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

@import "custom";
